import React, { Fragment, useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "components/Layout/Layout"
import Container from "components/Layout/Container"
import Section from "components/Elements/Section"
import ActionButtons from "components/Elements/ActionButtons"
import Message from "components/Elements/Message"

import { generateFormField } from "elements/Form/services/form"
import { useMentalHealthFormFields } from "./hooks/useMentalHealthFormFields"
import { MentalHealthContext } from "./MentalHealthContext/MentalHealthContext"
import { generateInitialValues } from "../../services/context"

import { isBrowser } from "../../services/general"
import { isObjectEmpty } from "services/general"

const BookingScreening = ({ pageContext }) => {
  const { mentalHealthState, mentalHealthDispatch } = useContext(
    MentalHealthContext
  )
  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  useEffect(() => {
    if (isBrowser()) {
      mentalHealthDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [mentalHealthDispatch])

  const handleSubmit = async (values) => {
    let nextPath = pageContext.nextPath

    await mentalHealthDispatch({
      type: "SAVE_KESSLER_6",
      payload: {
        ...values,
      },
    })

    if (values.hasWishedToBeDead.includes("Yes")){
      nextPath = `/${pageContext?.module?.name}/assessment`
    } else {
      nextPath =`/${pageContext?.module?.name}/schedule`
    }

    navigate(nextPath)
  }

  let initialValues = {
    ...generateInitialValues({
      fields: pageContext.formFields,
    }), ...mentalHealthState
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Next" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default BookingScreening
